<template>
  <!-- 识别记录 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-row>
          <el-col :span="24">
            <el-form-item label="时间：">
              <el-date-picker size="small" :default-time="['00:00:00', '23:59:59']" v-model="timeRange"
                value-format="yyyy-MM-dd HH:mm:ss" @blur="onSubmit()" type="datetimerange" range-separator="至"
                start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="场景：">
              <el-cascader popper-class="my-cascader" filterable v-model="queryForm.category_id_list"
                :options="mainFunAccCodeList" clearable :props="defaultParams" @change="handleChange" style="width:100%"
                placeholder="请选择场景">
                <template slot-scope="{ data }">
                  <span>{{ data.name }}</span>
                </template>
              </el-cascader>
            </el-form-item>
            <el-form-item label="设备名称：">
              <el-select popper-class="my-select" v-model="queryForm.aibox_id" placeholder="请选择识别设备" clearable
                @change="onSubmit()">
                <el-option v-for="item in selectData" :key="item.aibox_sn" :label="`${item.aibox_name}(${item.aibox_sn})`"
                  :value="item.Id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="通道：">
              <el-input class="paltetail" v-model="queryForm.alarm_chn_name" placeholder="请输入通道" @input="(e) => (queryForm.alarm_chn_name = validNumberLetter(e))
                " @keyup.enter.native="onSubmit()"></el-input>
            </el-form-item>
            <el-form-item label="识别类型：">
              <el-select popper-class="my-select" v-model="queryForm.alarm_type" placeholder="请选择识别类型" clearable
                @change="onSubmit()">
                <el-option v-for="item in getOptions('AiboxAlarmType')" :key="item.value" :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="!isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checked">自动刷新</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="isMultiRow">
          <el-col :span="24">
            <el-form-item label="校验类型：" v-if="isAdmin">
              <el-select popper-class="my-select" v-model="queryForm.is_succeeded" placeholder="请选择校验类型" clearable
                @change="onSubmit()">
                <el-option label="全部" :value="0"> </el-option>
                <el-option label="未校验" :value="2"> </el-option>
                <el-option label="已校验" :value="1"> </el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </lebo-query>
    <div class="mainBox">
      <div v-if="total">
        <!-- 主要内容 -->
        <ul class="centent_box">
          <div :class="item.is_succeeded ? 'content_item_success' : 'content_item'" v-for="(item, i) in tableData"
            :key="i">
            <el-carousel :autoplay="false" arrow="never" indicator-position="none">
              <el-carousel-item v-for="(data, index) in formatImage(item.capture_image)" :key="index">
                <el-image class="in-out-image" :src="data" :preview-src-list="formatImage(item.capture_image)">
                  <div slot="error" class="image-slot">
                    <i class="iconfont lebo-picerr"></i>
                    <span>加载失败</span>
                  </div>
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <!-- <el-image class="in-out-image" :src="item.capture_image" fit="contain"
            :preview-src-list="[item.capture_image]" v-else></el-image> -->
            <div class="devices_item_bottom">
              <div class="devices_ib_item">
                <div class="title" style="width: 100%;overflow: unset;">
                  {{ item.aibox_name }}
                </div>
              </div>
              <div class="devices_ib_item">
                <div class="title" :title="item.alarm_type_desc">
                  {{ item.alarm_type_desc }}
                </div>
                <div v-if="isAdmin">
                  <el-button type="success" plain size="mini" @click="onsucceed(item.Id)"
                    :style="item.is_succeeded === 1 ? 'display: none' : ''"
                    class="icon iconfont lebo-checkpass"></el-button>
                </div>
              </div>
              <div>
                <div class="title" :title="item.alarm_chn_name">
                  {{ item.alarm_chn_name ? item.alarm_chn_name : '' }}
                </div>
              </div>
              <div class="devices_ib_item" style="justify-content: start;color: #A6A6A6;">
                <div style="margin-right: 10px;" class="time"
                  :title='$moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")'> {{
                    $moment(item.create_time).format("YYYY-MM-DD HH:mm:ss") }} </div>
              </div>
            </div>
          </div>
        </ul>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[30, 40, 50, 60]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <div v-else class="noMessage"></div>
    </div>

    <!-- <le-card title="识别记录">
      </le-card> -->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  getAiBoxDeviceRecordPageList,
  getAiBoxDeviceSelectList,
  CalibrationRecord, getBindScene
} from '@/api/monitoring'

export default {
  data () {
    return {
      checked: false,
      timeRange: null,
      // 查询表单
      queryForm: {
        PageSize: 30,
        PageIndex: 1,
        begintime: '',
        endtime: '',
        alarm_type: null,
        is_succeeded: 0,
        aibox_sn: '',
        aibox_id: '', // 分析盒子id
        alarm_chn_name: '', // 通道名称
        category_id_list: []
      },
      total: 0,
      // 表格数据
      tableData: [],
      selectData: [],
      // 场景列表
      mainFunAccCodeList: [],
      isMultiRow: true, // 查询下拉弹窗
      defaultParams: {
        label: 'name',
        value: 'Id',
        children: 'children'
      }
    }
  },
  created () {
    this.fnGetAiBoxDeviceRecordPageList()
    this.fngetAiBoxDeviceSelectList()
    this.fngetBindScene()
    this.Interval()
  },
  mounted () { },
  destroyed () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {
    timeRange (newV, oldV) {
      if (newV) {
        this.queryForm.begintime = newV[0]
        this.queryForm.endtime = newV[1]
      } else {
        this.queryForm.begintime = ''
        this.queryForm.endtime = ''
      }
      this.onSubmit()
    }
  },
  methods: {
    // 获取场景列表
    async fngetBindScene () {
      const res = await getBindScene()
      this.mainFunAccCodeList = this.filterChildren(res.Data)
    },
    handleChange (value) {
      this.queryForm.category_id_list = value
      this.onSubmit()
    },
    async fnGetAiBoxDeviceRecordPageList () {
      const res = await getAiBoxDeviceRecordPageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        begintime: this.queryForm.begintime,
        endtime: this.queryForm.endtime,
        alarm_type: this.queryForm.alarm_type
          ? this.queryForm.alarm_type
          : 0,
        is_succeeded: this.queryForm.is_succeeded
          ? this.queryForm.is_succeeded
          : 0,
        // aibox_sn: this.queryForm.aibox_sn,
        aibox_id: this.queryForm.aibox_id,
        alarm_chn_name: this.queryForm.alarm_chn_name,
        category_id_list: this.queryForm.category_id_list
      })
      if (res && res.Code === 200) {
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
      } else {
        this.total = 0
        this.tableData = []
      }
    },
    async fngetAiBoxDeviceSelectList () {
      const res = await getAiBoxDeviceSelectList()
      console.log('获取设备下拉选', res)
      if (res && res.Code === 200) {
        this.selectData = res.Data
      } else {
        this.selectData = []
      }
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAiBoxDeviceRecordPageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetAiBoxDeviceRecordPageList()
    },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetAiBoxDeviceRecordPageList()
      // console.log(`当前页: ${val}`);
    },
    async onsucceed (id) {
      const res = await CalibrationRecord({
        id: id
      })
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
        this.fnGetAiBoxDeviceRecordPageList()
      }
    },
    Interval () {
      window.setInterval(() => {
        if (this.checked) {
          this.onSubmit()
        }
      }, 1000)
    },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    }
  }
}
</script>

<style scoped lang="less">
.centent_box {
  height: 680px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .devices_item_bottom {
    // margin-top: -5px;
    height: 100px;
  }

  /deep/ .title {
    width: inherit;
    overflow: hidden;
    /* 超出一行文字自动隐藏 */
    text-overflow: ellipsis;
    /* 文字隐藏后添加省略号 */
    white-space: nowrap;
    /* 强制文本不换行 */
    cursor: pointer;
    margin-top: 5px;
    // &:hover {
    //   text-overflow: inherit;
    //   overflow: visible;
    //   white-space: pre-line;
    // }
  }

  /deep/ .content_item {
    width: 9%;
    height: 30%;
    padding: 6px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    // margin-right: 0.7%;
    // margin-bottom: 8px;
    margin: 8px 0.5%;

    .in-out-image {
      width: 100%;
      height: 100%;
      border-radius: 8px;

      .image-slot {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .iconfont {
          font-size: 36px;
        }
      }
    }

    .devices_ib_item {
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        width: inherit;
        overflow: hidden;
        /* 超出一行文字自动隐藏 */
        text-overflow: ellipsis;
        /* 文字隐藏后添加省略号 */
        white-space: nowrap;
        /* 强制文本不换行 */
      }
    }
  }

  .content_item_success {
    width: 12%;
    padding: 6px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-right: 0.5%;
    margin-bottom: 8px;

    .devices_ib_item {
      width: inherit;
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        width: inherit;
        overflow: hidden;
        /* 超出一行文字自动隐藏 */
        text-overflow: ellipsis;
        /* 文字隐藏后添加省略号 */
        white-space: nowrap;
        /* 强制文本不换行 */
        cursor: pointer;
        // &:hover {
        //   text-overflow: inherit;
        //   overflow: visible;
        //   white-space: pre-line;
        // }
      }

      .time {
        width: inherit;
        overflow: hidden;
        /* 超出一行文字自动隐藏 */
        text-overflow: ellipsis;
        /* 文字隐藏后添加省略号 */
        white-space: nowrap;
        /* 强制文本不换行 */
      }
    }
  }
}

.el-carousel {
  height: 95%;

  /deep/ .el-carousel__container {
    height: inherit;

    .el-carousel__arrow {
      width: 15px;
      height: 15px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .el-carousel__item {
      height: inherit;
    }
  }
}

.noMessage {
  height: 700px;
  width: 100%;
  background-image: url('~@/assets/img/ychj/nomessage.png');
  background-repeat: no-repeat;
  background-size: 16% 35%;
  background-position: center center;
}

.queryBox {
  margin-bottom: 20px;
}

/deep/ .el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small {
  width: 160px !important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 280px;
}
</style>
