/**
 *  监控相关请求
 */
import axios from './http'

//  获取场景集合接口
export const getSceneList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/SceneList',
    params
  })
}

// 保存场景数据接口
export const AddScene = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/SceneAddOrEdit',
    data
  })
}

// 删除场景数据接口
export const Delscene = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/DelSceneList',
    data
  })
}

// 获取场景树控件详情接口
export const getSceneTreeDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/SceneDetail',
    params
  })
}

// 新增/编辑类别接口
// export const setSceneTreeDetail  = (data) => {
//     return axios({
//         method: 'post',
//         url: '/ParkingLotApi/MonitoringManager/CategorySave',
//         data
//     });
// }
// 组织架构新增
export const addCategory = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/CategoryAdd',
    data
  })
}
// 组织架构修改
export const updateCategory = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/CategoryUpdate',
    data
  })
}
// 组织架构批量删除
export const delCategory = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/DelSceneTreesList',
    data
  })
}

// NVR数据新增或编辑接口
export const setNVRSave = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/NVRAddOrEdit',
    data
  })
}

// 获取NVR集合
export const getNVRList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/NVRList',
    params
  })
}

// 获取场景数据整体详情
export const getBindScene = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/SceneListDetail',
    params
  })
}

// 获取设备RTSP直播流地址
export const getMonitorUrlList = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/NVR_Monitor',
    data
  })
}

// 获取nvr详情
export const getNVRDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/NVRDetail',
    params
  })
}

// 删除NVR数据接口
export const DelNVR = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/DelNVRList',
    data
  })
}

// 设备集合接口
export const getMonitorList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/MonitorList',
    params
  })
}

// 获取nvr下拉列
export const getNVRSelctList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/NVRSelect',
    params
  })
}

// 保存监控设备
export const setMonitorSave = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/MonitorAddOrEdit',
    data
  })
}

// 设备新增或编辑页面展示接口
export const getMonitorAddOrEdit = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/MonitorDetail',
    params
  })
}

// 删除监控设备
export const setMonitorDel = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/DelMonitorList',
    data
  })
}

// 获取流媒体服务器列表
export const getMediaServerList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetMediaServerList',
    params
  })
}

// 场景下配置得监控设备列表获取
export const getEquipmentList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/getSenceIdMonitorList',
    params
  })
}
// 预览配置界面
export const getPreviewView = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/PreviewView',
    params
  })
}

// // 保存预览数据接口
// export const setPreviewUpdate  = (data) => {
//     return axios({
//         method: 'post',
//         url: '/ParkingLotApi/MonitoringManager/previewUpdate',
//         data
//     });
// }
// 预览配置修改
export const setPreviewUpdate = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/PreviewUpdate',
    data
  })
}
// 开始录制
export const startRecord = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/StartRecord',
    data
  })
}
// 停止录制
export const stopRecord = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/StopRecord',
    data
  })
}
// 新增录制计划
export const createRecordPlan = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/CreateRecordPlan',
    data
  })
}
// 编辑录制计划
export const setRecordPlanByIdNew = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/SetRecordPlanByIdNew',
    data
  })
}
// 分页获取录制计划
export const getRecordPlanListParam = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetRecordPlanListParam',
    params
  })
}
// 批量删除录制计划
export const deleteRecordPlanBatchById = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/DeleteRecordPlanBatchById',
    data
  })
}
// 获取录制计划详情
export const getRecordPlanDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetRecordPlanDetail',
    params
  })
}
// 根据录制id获取监控设备
export const getVideoChannelByRecordPlanId = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetVideoChannelByRecordPlanId',
    params
  })
}
// 获取录制计划下拉设备列表
export const recordPlanSceneDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/RecordPlanSceneDetail',
    params
  })
}
// 获取视频录制记录
export const getRecordFileList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetRecordFileList',
    params
  })
}
// 启用或终止录制计划
export const setRecordPlanEnable = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/SetRecordPlanEnable',
    data
  })
}

// 新增抓拍规则
export const createCapturePlan = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/CreateCapturePlan',
    data
  })
}

// 编辑抓拍规则
export const updateCapturePlan = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/UpdateCapturePlan',
    data
  })
}

// 分页获取抓拍计划
export const getCapturePlanListParam = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetCapturePlanListParam',
    params
  })
}

// 批量删除抓拍计划
export const deleteCapturePlan = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/DeleteCapturePlan',
    data
  })
}

// 获取抓拍计划详情
export const getCapturePlanDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetCapturePlanDetail',
    params
  })
}

// 根据抓拍id获取监控设备
export const getVideoChannelByCapturePlanId = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetVideoChannelByCapturePlanId',
    params
  })
}

// 获取抓拍计划下拉设备列表
export const capturePlanSceneDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/CapturePlanSceneDetail',
    params
  })
}

//  启用或终止抓拍规则
export const setCapturePlanEnable = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/SetCapturePlanEnable',
    data
  })
}

// 获取视频抓拍记录
export const getCaptureRecordList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GetCaptureRecordList',
    params
  })
}
// 新增集团国标授权
export const gBAuthorizedAdd = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/GBAuthorizedAdd',
    data
  })
}
// 编辑集团国标授权
export const gBAuthorizedEdit = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/GBAuthorizedEdit',
    data
  })
}
// 获取国标授权集团下拉列表（排除已授权的集团）
export const gBAuthorizedGroupList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GBAuthorizedGroupList',
    params
  })
}
// 获取国标授权集团分页列表
export const gBAuthorizedGroupListParam = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/MonitoringManager/GBAuthorizedGroupListParam',
    params
  })
}
// 删除集团国标授权
export const deleteGBAuthorized = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/DeleteGBAuthorized',
    data
  })
}

// 新增边端盒子设备
export const addAiBoxDevice = (data) => axios.post('/ParkingLotApi/MonitoringManager/Add', data)
// 编辑边端盒子设备
export const updateAiBoxDevice = (data) => axios.post('/ParkingLotApi/MonitoringManager/Update', data)
// 删除边端盒子设备
export const delAiBoxDevice = (data) => axios.post('/ParkingLotApi/MonitoringManager/Delete', data)
// 获取边端盒子设备列表
export const getAiBoxDevicePageList = (data) => axios.post('/ParkingLotApi/MonitoringManager/GetPageList', data)
// 获取边端盒子下拉框列表
export const getAiBoxDeviceSelectList = (params) => axios.get('/ParkingLotApi/MonitoringManager/GetSelectList', { params: params })
// 获取边端盒子设备抓拍记录列表
export const getAiBoxDeviceRecordPageList = (data) => axios.post('/ParkingLotApi/MonitoringManager/GetRecordPageList', data)
// 以图搜图（监控）
export const searchFaceByMonitor = (data) => axios.post('/SecurityApi/FaceFind/SearchFaceByMonitor', data)
// 校验抓拍记录
export const CalibrationRecord = (data) => axios.post('/ParkingLotApi/MonitoringManager/CalibrationRecord', data)
// 修改通道信息
export const changeChannelDetail = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/MonitoringManager/ChannelConfig',
    data
  })
}
